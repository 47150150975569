<template>
  <div>
    <v-container >
      <v-row>
        <v-col cols='12'>
          <v-card-title >
            4. Воздушно-пенный огнетушитель (ОВП)
          </v-card-title>
        </v-col>
        <v-col cols="12">
          <v-alert
            border="bottom"
            colored-border
            color="red"
            class="title font-weight-light pl-10"
            elevation="2"
          >
          <div class="d-flex flex-column">
              <p><strong>Принцип действия: </strong>При срабатывании
              <v-tooltip color="red" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    color="red"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="title font-weight-light blue lighten-4"
                  >
                     ЗПУ
                  </span>
                </template>
                <span>Запорно-пусковое устройство</span>
              </v-tooltip>
               прокалывается заглушка баллона с рабочим газом.
                <v-tooltip color="red" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    color="red"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="title font-weight-light blue lighten-4"
                  >
                     Пенообразователь
                  </span>
                </template>
                <span><strong>Пенообразователи</strong> – вещества, участвующие в образовании устойчивой пены.<br>Составляет 4-6 % от объема всего заряда</span>
              </v-tooltip>
                выдавливается газом через каналы и сифонную трубку.</p>
          </div>

          </v-alert>
        </v-col>
        <v-col cols="6">
          <v-tabs v-model="tab1" active-class="ptm-act-tab-class" color="red" grow background-color="grey lighten-4">
            <v-tab>Схема огнетушителя</v-tab>
            <v-tab>3D модель огнетушителя</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab1">

            <v-tab-item>
              <v-card flat class="ma-5 ptm-img-relative">
                <v-img
                  lazy-src="img/material/exp-3-3.png"
                  max-width="100%"
                  src="img/material/exp-3-3.png"
                  class="ma-1"
                ></v-img>
                <v-tooltip left color="red">
                  <template v-slot:activator="{ on }">
                    <v-btn dark v-on="on" rounded class="ptm-ovp-1 title pa-5 red">1</v-btn>
                  </template>
                  <span class="pa-5 pl-0 subtitle-1">Запорно-пусковое устройство</span>
                </v-tooltip>
                <v-tooltip left color="red">
                  <template v-slot:activator="{ on }">
                    <v-btn dark v-on="on" rounded class="ptm-ovp-2 title pa-5 red">2</v-btn>
                  </template>
                  <span class="pa-5 subtitle-1">Насадка</span>
                </v-tooltip>
                <v-tooltip right color="red">
                  <template v-slot:activator="{ on }">
                    <v-btn dark v-on="on" rounded class="ptm-ovp-3 title pa-5 red">3</v-btn>
                  </template>
                  <span class="pa-5 pl-0 subtitle-1">Баллон с рабочим газом</span>
                </v-tooltip>
                <v-tooltip right color="red">
                  <template v-slot:activator="{ on }">
                    <v-btn dark v-on="on" rounded class="ptm-ovp-4 title pa-5 red">4</v-btn>
                  </template>
                  <span class="pa-5 subtitle-1">Раствор пенообразователя</span>
                </v-tooltip>
                <v-tooltip right color="red">
                  <template v-slot:activator="{ on }">
                    <v-btn dark v-on="on" rounded class="ptm-ovp-5 title pa-5 red">5</v-btn>
                  </template>
                  <span class="pa-5 subtitle-1">Сифонная трубка</span>
                </v-tooltip>

              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat class="ma-5">
                <model-viewer
                  style="height:400px;width:100%; outline:red;"
                  src="img/3d/ovp.gltf" alt="A 3D model of an astronaut"
                  camera-orbit="390deg 80deg 30m"
                  camera-target="0m 6m 0m"
                  camera-controls>
                </model-viewer>
                <v-sheet flat class="d-flex justify-center mx-auto pb-0 mt-2">
                  <v-card class="d-flex justify-center" flat>
                    <v-icon color="red" size="40">mdi-cursor-default-gesture-outline</v-icon>
                    <v-card-title class="font-weight-light">
                      Вращайте модель при помощи мыши
                    </v-card-title>
                  </v-card>
                </v-sheet>
              </v-card>
            </v-tab-item>

          </v-tabs-items>
        </v-col>
        <v-col cols="6">
          <v-card class="mb-4">
            <v-tabs color="red" grow v-model="tab2">
              <v-tab>Назначение</v-tab>
              <v-tab>Правила обращения</v-tab>
            </v-tabs>
          </v-card>
          <v-card class="pa-2 pb-5">
            <v-tabs-items v-model="tab2">
              <v-tab-item>
                <v-card-title class="">
                Предназначены для подавления возгораний:
                </v-card-title>
                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  горючих жидкостей (класс В);
                </v-card-text>
                <v-card-text class="title font-weight-light">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  тлеющих твердых материалов (класс А).
                </v-card-text>
                <v-card-title class="red--text">
                Запрещено применять для:
                </v-card-title>
                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  электрооборудования под напряжением;
                </v-card-text>
                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  щелочных металлов и материалов, горение которых происходит без участия кислорода.
                </v-card-text>
                <v-card-title class="">
                  Применяют только при положительной температуре воздуха от +5 до +45°С.
                </v-card-title>
              </v-tab-item>
              <v-tab-item>
                <v-card class="pa-5">

                <v-card-title class="">
                  Правила обращения:
                </v-card-title>

                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  огнетушитель держать в вертикальном положении;
                </v-card-text>

                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  длительность подачи ОТВ 20-60 секунд в зависимости от объема устройства;
                </v-card-text>

                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  тушить с наветренной стороны на расстоянии не ближе 3 м от огня;
                </v-card-text>

                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                  перезарядка 1 раз в год или после его применения.
                </v-card-text>

                <v-card-title class="red--text">
                  Запрещено:
                </v-card-title>

                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-alert</v-icon>
                  воздействие атмосферных осадков;
                </v-card-text>

                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-alert</v-icon>
                  нагрев баллона;
                </v-card-text>

                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-alert</v-icon>
                  удары по корпусу, ЗПУ и соединительной трубке;
                </v-card-text>

                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-alert</v-icon>
                  срывать пломбы;
                </v-card-text>

                <v-card-text class="title font-weight-light pb-0">
                  <v-icon color="red" class="mr-3">mdi-alert</v-icon>
                  использовать огнетушители с вышедшим из строя предохранительным клапаном.
                </v-card-text>

                </v-card>

              </v-tab-item>
            </v-tabs-items>
          </v-card>

        </v-col>
      </v-row>
      <Hint></Hint>
    </v-container>

  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    tab1: null,
    tab2: null
  }),
  components: {
    Hint
  }
}
</script>
<style lang="less">
.ptm-act-tab-class {
  background-color: #FFCDD2;
  }
.ptm-img-relative {
  position: relative;
}
.ptm-ovp-1 {
  position: absolute;
  top: 12%;
  left: 7%;
}
.ptm-ovp-2 {
  position: absolute;
  top: 72%;
  left: 8%;
}
.ptm-ovp-3 {
  position: absolute;
  top: 22%;
  left: 75%;
}
.ptm-ovp-4 {
  position: absolute;
  top: 64%;
  left: 75%;
}
.ptm-ovp-5 {
  position: absolute;
  top: 81%;
  left: 75%;
}
</style>
